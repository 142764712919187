import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EnrollmentService } from 'src/app/api/enrollment.service';


@Component({
  selector: 'cayston-opt-out',
  templateUrl: './opt-out.component.html',
  styleUrls: ['./opt-out.component.scss']
})
export class OptOutPage implements OnInit {

  userId: string;
  constructor(private router: Router,
              private gaService: GoogleAnalyticsService,
              private enrollmentService: EnrollmentService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(q => this.userId = q.token);
  }

  doOptOut() {
    // TODO: call API to opt out


    this.enrollmentService.optOut({userId: this.userId}).subscribe(this.handleResponse.bind(this));

    this.gaService.event('click', 'internal', 'OPT OUT NOW');

    this.router.navigate(['opt-out-confirm']);
  }

  //

  handleResponse(success: any) {
    console.log(success);
  }
}
