import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { EnrollmentData, EmailExistsResult, EmailQualityResult } from '../models/enrollment-data';
import { SurveyReprofileData } from '../models/survey-cayc0460-data';


const ENROLLMENT_URL = environment.apiUrl + '/user';
const USER_EXISTS_CHECK_URL = environment.apiUrl + '/user/exists';
const EMAIL_CHECK_URL = environment.apiUrl + '/user/validate/email';
const OPTOUT_URL = environment.apiUrl + '/user/optoutNotify';
const VALID_GRADES = ['A+', 'A', 'B'];


@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  userId$ = new BehaviorSubject<string>("");
  constructor(private http: HttpClient) { }


  enroll(data: EnrollmentData) {
    return this.http
               .post(ENROLLMENT_URL, data)
               .pipe(catchError(this.handleError()));
  }

  reprofile(data: SurveyReprofileData, id: string) {
    return this.http
               .put(ENROLLMENT_URL + '/' + id, data)
               .pipe(catchError(this.handleError()));
  }

  isExists(data: { emailAddress: string }) {
    return this.http
               .post<EmailExistsResult>(USER_EXISTS_CHECK_URL, data)
               .pipe(map(res => res.isExists ? ({ exists: res.isExists }) : null));
  }

  isValid(data: {emailAddress: string}) {
    return this.http
               .post<EmailQualityResult>(EMAIL_CHECK_URL, data)
               .pipe(map(res => !VALID_GRADES.includes(res.grade) ? ({ reject: true }) : null));
  }

  optOut(data: {userId: string}) {
    return this.http
               .put(OPTOUT_URL, data)
               .pipe(catchError(this.handleError()));
  }

  updateUserId(userId: string) {
    setTimeout(() => { this.userId$.next(userId); }, 0);
  }

  ///

  private handleError() {
    return (err: any) => {
      console.error('ERROR:', err.error.message);

      let error = new Error();
      error.message = err.error.message;

      return of(error);
    };
  }
}
