import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SurveyCAYC0460Data, SurveyEnrollThanksData, SurveyRatingData, SurveyReprofileData } from '../models/survey-cayc0460-data';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  private SURVEY_URL = environment.apiUrl + '/surveys';

  constructor(private http: HttpClient) { }


  create(data: SurveyCAYC0460Data) {
    return this.http
               .post(this.SURVEY_URL + '/cayc0460', data)
               .pipe(
                 map(res => res),
                 catchError(this.handleError())
               );
  }

  createEnrollThanksSurvey(data: SurveyEnrollThanksData) {
    return this.http
               .post(this.SURVEY_URL + '/enroll-thanks', data)
               .pipe(
                 map(res => res),
                 catchError(this.handleError())
               );
  }

  createRatingSurvey(data: SurveyRatingData) {
    return this.http
               .post(this.SURVEY_URL + '/rate', data)
               .pipe(
                 map(res => res),
                 catchError(this.handleError())
               );
  }

  // createReprofileSurvey(data: SurveyReprofileData) {
  //   return this.http
  //              .post(this.SURVEY_URL + '/reprofile-survey', data)
  //              .pipe(
  //                map(res => res),
  //                catchError(this.handleError())
  //              );
  // }

  ///

  handleError() {
    return (err: any) => {
      console.error('ERROR:', err.error.message);
      let error = new Error();
      error.message = err.error.message;
      return of(error);
    };
  }
}
