import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlternateHeaderService {

  private alternateHeader$ = new BehaviorSubject<boolean>(false);

  get alternateHeader() {
    return this.alternateHeader$
               .asObservable()
               .pipe(
                map(id => id ? id : false),
                shareReplay(1)
              );
  }

  updateArticle(alternateHeader: boolean) {
    setTimeout(() => { this.alternateHeader$.next(alternateHeader); }, 0);
  }
}
