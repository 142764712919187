import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxHotjarModule } from 'ngx-hotjar';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { EnrollmentPage } from './pages/enrollment/enrollment.component';
import { ThankYouPage } from './pages/thank-you/thank-you.component';
import { OptOutPage } from './pages/opt-out/opt-out.component';
import { OptOutConfirmPage } from './pages/opt-out-confirm/opt-out-confirm.component';
import { SurveyPage } from './pages/survey/survey.component';
import { SurveyConfirmPage } from './pages/survey-confirm/survey-confirm.component';

import { environment } from '../environments/environment';
import { EnrollThankyouSurveyPage } from './pages/enroll-thankyou-survey/enroll-thankyou-survey.page';



@NgModule({
  declarations: [
    AppComponent,
    EnrollmentPage,
    ThankYouPage,
    OptOutPage,
    OptOutConfirmPage,
    SurveyPage,
    SurveyConfirmPage,
    EnrollThankyouSurveyPage
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingID),
    NgxGoogleAnalyticsRouterModule,
    environment.production ? NgxHotjarModule.forRoot(environment.hj) : [],
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
