import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EnrollmentService } from 'src/app/api/enrollment.service';

import { SurveysService } from 'src/app/api/surveys.service';

@Component({
  selector: 'cayston-enroll-thankyou-survey',
  templateUrl: './enroll-thankyou-survey.page.html',
  styleUrls: ['./enroll-thankyou-survey.page.scss']
})
export class EnrollThankyouSurveyPage {

  // q1Options = ['Highly<br>satisfied', 'Somewhat<br>satisfied', 'Neither satisfied<br>nor dissatisfied', 'Somewhat<br>dissatisfied', 'Highly<br>dissatisfied'];
  // q2Options = ['', 'Strongly like', 'Like', 'Neutral', 'Dislike', 'Strongly dislike'];
  // q6Options = ['Very likely', 'Somewhat likely', 'Neutral', 'Somewhat unlikely', 'Very unlikely'];
  // ageRanges = ['18-25', '26-35', '36-45', '46-55', '56-65', '66-75', '76+'];
  // iAmAOptions = ['Person with CF', 'CF spouse', 'CF caregiver', 'CF parent'];

  checkboxOutput: string[] = [];
  submitted = false;

  form: FormGroup;
  userId: string;
  c: string;

  constructor(private fb: FormBuilder,
              // private el: ElementRef,
              private route: ActivatedRoute,
              private gaService: GoogleAnalyticsService,
              private router: Router,
              private enrollmentService: EnrollmentService,
              private surveysService: SurveysService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      contentLikeSeeMCC: ['']
    });

    this.route.queryParams.subscribe(q => {
      this.c = q.c;
    });

    this.enrollmentService.userId$.subscribe( u => this.userId = u);

  }

  isCheckboxInvalid(field: string) {
    // const control = this.form.get(field);

    // if (control === null) {
    //   console.error(`Field "${field}" not found in form`);
    //   return;
    // }

    // let x = (
    //   (this.checkboxOutput.length < 1 && control.touched) ||
    //   (control.untouched && this.submitted)
    // );

    // this.submitted && control.markAsTouched();

    // return x;
  }

  onCheckChange(event: Event) {
    const elem = event.target as HTMLInputElement;
    const controlName = elem.attributes.getNamedItem('formcontrolname')!.nodeValue;
    const control = this.form.get(controlName!)!;

    if (elem.checked){
      this.checkboxOutput.push(elem.value);
    }
    else {
      let i = this.checkboxOutput.indexOf(elem.value);
      this.checkboxOutput.splice(i, 1);
     // control.setErrors(this.checkboxOutput.length < 1 ? { required: true } : null);
    }
  }

  submitSurvey() {
    this.submitted = true;

    // if (this.form.invalid) {
    //   // this.scrollToFirstInvalidControl();
    //   return;
    // }

    if (this.checkboxOutput.length > 0) {
      const postData = {
        contentLikeSeeMCC: this.checkboxOutput,
        userId: this.userId,
        c: this.c
      };

      this.surveysService
          .createEnrollThanksSurvey(postData)
          .subscribe(this.handleResponse.bind(this));

      this.gaService.event('click', 'internal', 'SUBMIT SURVEY ENROLL THANKS');
    } else {
      this.gaService.event('click', 'internal', 'SKIPPED SURVEY ENROLL THANKS');
    }

    this.router.navigate(['/thank-you']);
  }

  // generateArray(n: number): number[] {
  //   return Array.from(Array(n).keys());
  // }

  //

  private handleResponse(success: any) {
    console.log(success);
  }

  // private scrollToFirstInvalidControl() {
  //   const firstInvalidControl: HTMLElement[] = this.el.nativeElement.querySelectorAll('.ng-invalid');
  //   const first = firstInvalidControl[1]; //without smooth behavior
  //   // first.focus();
  //   const anchorId = first.id.substring(0, first.id.length - 2);

  //   const aa: HTMLElement = this.el.nativeElement.querySelector("#anchor-" + anchorId);
  //   aa.focus();

  //   window.scroll({
  //     top: this.getTopOffset(aa),
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }

  // private getTopOffset(controlEl: HTMLElement): number {
  //   const labelOffset = 0;
  //   return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  // }

}
