import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnrollmentPage } from './pages/enrollment/enrollment.component';
import { ThankYouPage } from './pages/thank-you/thank-you.component';
import { OptOutPage } from './pages/opt-out/opt-out.component';
import { OptOutConfirmPage } from './pages/opt-out-confirm/opt-out-confirm.component';
import { SurveyPage } from './pages/survey/survey.component';
import { SurveyConfirmPage } from './pages/survey-confirm/survey-confirm.component';
import { EnrollThankyouSurveyPage } from './pages/enroll-thankyou-survey/enroll-thankyou-survey.page';


const routes: Routes = [
  { path: 'enrollment', component: EnrollmentPage },
  { path: 'thank-you', component: ThankYouPage },
  { path: 'opt-out', component: OptOutPage },
  { path: 'opt-out-confirm', component: OptOutConfirmPage },
  { path: 'survey/cayc0460', component: SurveyPage },
  { path: 'enrollment/thanks-survey', component: EnrollThankyouSurveyPage },
  { path: 'survey-confirm', component: SurveyConfirmPage },

  { path: '', pathMatch: 'full', redirectTo: 'enrollment' },
  { path: '**', redirectTo: 'enrollment' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
