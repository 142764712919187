import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AsyncValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, switchMap, first } from 'rxjs/operators';

import { EnrollmentService } from '../../api/enrollment.service';

declare function videoControl():any;

@Component({
  selector: 'cayston-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentPage implements OnInit {

  nameRegex = /[a-zA-Z][^#&<>\"~;$^%{}?]{2,20}$/;
  detailsVisible = false;
  submitted = false;

  form: FormGroup;
  cParam: string;


  constructor(private fb: FormBuilder,
              private router: Router,
              private gaService: GoogleAnalyticsService,
              private route: ActivatedRoute,
              private enrollmentService: EnrollmentService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      fullName: ['', Validators.pattern(this.nameRegex)],
      email: ['', Validators.compose([Validators.required, Validators.email]),
                  Validators.composeAsync([this.validateEmailIsExist(), this.validateEmail()])],
      acceptTerms: ['', Validators.required]
    });

    this.route.queryParams.subscribe(q => this.cParam = q.c);
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);

    if (control === null) {
      console.error(`Field "${field}" not found in form`);
      return;
    }

    return (
      (control.invalid && control.touched) ||
      (control.untouched && this.submitted)
    );
  }

  closeVideo() {
    this.detailsVisible = false;
    videoControl();
  }

  doEnroll() {
    this.submitted = true;

    if (this.form.invalid || this.form.pending)
      return;

    const postData = {
      fullName: this.form.value.fullName.trim(),
      emailAddress: this.form.value.email.toLowerCase(),
      c: this.cParam
    };

    this.enrollmentService.enroll(postData).subscribe(this.handleResponse.bind(this));

    this.gaService.event('click', 'internal', 'JOIN NOW');

    this.router.navigate(['/enrollment/thanks-survey']);
  }

  //

  private validateEmailIsExist(): AsyncValidatorFn {
    return control => control.valueChanges
    .pipe(
      debounceTime(800),
      // distinctUntilChanged(),
      switchMap(value => this.enrollmentService.isExists({emailAddress: control.value.toLowerCase()})),
      first());
  }

  private validateEmail(): AsyncValidatorFn {
    return control => control.valueChanges
    .pipe(
      debounceTime(400),
      // distinctUntilChanged(),
      switchMap(value => this.enrollmentService.isValid({emailAddress: control.value.toLowerCase()})),
      first());
  }

  handleResponse(success: any) {
    console.log(success);
    this.enrollmentService.updateUserId(success.id);
  }
}
