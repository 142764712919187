import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AlternateHeaderService } from 'src/app/api/alternate-header.service';
import { PageArticleService } from 'src/app/api/page-article.service';
import { SurveysService } from 'src/app/api/surveys.service';
import { EnrollmentService} from 'src/app/api/enrollment.service';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'cayston-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouPage implements OnInit {

  userId: string;
  c: string;
  rate: number;
  describesYou: string;
  submitted: boolean = false;
  isAltHead: boolean;

  constructor(private alternateHeaderService: AlternateHeaderService,
              private pageArticleService: PageArticleService,
              private surveysService: SurveysService,
              private enrollmentService: EnrollmentService,
              private gaService: GoogleAnalyticsService,
              private router: Router,
              private route: ActivatedRoute) { 
    this.alternateHeaderService.updateArticle(true); 
    this.pageArticleService.updateArticle("US-CAYC-0072 05/22");

  }

  ngOnInit(): void {
    this.alternateHeaderService.alternateHeader.subscribe( alt => {
      this.isAltHead = alt;
    })
    if (this.isAltHead) {
      this.route.queryParams.subscribe(q => {
        // Rating survey
        this.rate = +q.rate;
        if (!!this.rate && this.rate !== NaN && this.rate > 0 && this.rate <= 5 ) {
          console.log(this.rate);
          this.c = q.c;
          this.userId = q.id;
          this.submitRateSurvey();
        }

        // Reprofile Survey
        this.describesYou = q['describes-you'];
        this.c = q.c;
        if (this.c === 'us-cayc-0074' && !!this.describesYou) {
          this.userId = q.id;
          this.submitReprofileSurvey();
        }
      });
    }
  }

  submitRateSurvey() {
    if (this.submitted) return;

    const postData = {
      rate: this.rate,
      userId: this.userId,
      c: this.c
    };

    this.surveysService
        .createRatingSurvey(postData)
        .subscribe(this.handleResponse.bind(this));

    this.gaService.event('click', 'internal', 'RATING SURVEY');

    this.submitted = true;
  }

  submitReprofileSurvey() {
    if (this.submitted) return;

    const postData = {
      describesYou: this.describesYou,
      userId: this.userId,
    };

    this.enrollmentService
        .reprofile(postData, this.userId)
        .subscribe(this.handleResponse.bind(this));

    this.gaService.event('click', 'internal', 'REPROFILE SURVEY');

    this.submitted = true;
  }

  //

  private handleResponse(success: any) {
    console.log(success);
  }

}
