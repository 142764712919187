import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { SurveysService } from '../../api/surveys.service';


@Component({
  selector: 'cayston-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyPage {

  q1Options = ['Highly<br>satisfied', 'Somewhat<br>satisfied', 'Neither satisfied<br>nor dissatisfied', 'Somewhat<br>dissatisfied', 'Highly<br>dissatisfied'];
  q2Options = ['', 'Strongly like', 'Like', 'Neutral', 'Dislike', 'Strongly dislike'];
  q6Options = ['Very likely', 'Somewhat likely', 'Neutral', 'Somewhat unlikely', 'Very unlikely'];
  ageRanges = ['18-25', '26-35', '36-45', '46-55', '56-65', '66-75', '76+'];
  iAmAOptions = ['Person with CF', 'CF spouse', 'CF caregiver', 'CF parent'];

  checkboxOutput: string[] = [];
  submitted = false;

  form: FormGroup;
  userId: string;
  c: string;

  constructor(private fb: FormBuilder,
              private el: ElementRef,
              private route: ActivatedRoute,
              private gaService: GoogleAnalyticsService,
              private router: Router,
              private surveysService: SurveysService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      rateSatisfactionLevel: ['', Validators.required],
      rateSatisfactionEmail: ['', Validators.required],
      rateSatisfactionEmailVideoMsg: ['', Validators.required],
      rateSatisfactionWebsiteArticle: ['', Validators.required],
      rateSatisfactionWebsiteVideo: ['', Validators.required],
      satisfactionWithFrequencyOfEmail: ['', Validators.required],
      whatTypeOfContentWouldYouLikeToSeeMore: ['', Validators.required],
      iAmMostInterestedInReadingArticlesWrittenBy: ['', Validators.required],
      rateRecommendToFriendOrFamily: [''],
      gender: [''],
      ageRange: [''],
      iAmA: ['']
    });

    this.route.queryParams.subscribe(q => {
      this.c = q.c;
      this.userId = q.userId;
    });
  }


  isFieldInvalid(field: string) {
    const control = this.form.get(field);

    if (control === null) {
      console.error(`Field "${field}" not found in form`);
      return;
    }

    control.value && control.markAsTouched();

    return (
      (control.invalid && control.touched) ||
      (control.untouched && this.submitted)
    );
  }

  isCheckboxInvalid(field: string) {
    const control = this.form.get(field);

    if (control === null) {
      console.error(`Field "${field}" not found in form`);
      return;
    }

    let x = (
      (this.checkboxOutput.length < 1 && control.touched) ||
      (control.untouched && this.submitted)
    );

    this.submitted && control.markAsTouched();

    return x;
  }

  onCheckChange(event: Event) {
    const elem = event.target as HTMLInputElement;
    const controlName = elem.attributes.getNamedItem('formcontrolname')!.nodeValue;
    const control = this.form.get(controlName!)!;

    if (elem.checked){
      this.checkboxOutput.push(elem.value);
    }
    else {
      let i = this.checkboxOutput.indexOf(elem.value);
      this.checkboxOutput.splice(i, 1);
      control.setErrors(this.checkboxOutput.length < 1 ? { required: true } : null);
    }
  }

  submitSurvey() {
    this.submitted = true;

    if (this.form.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }

    const postData = {
      rateSatisfactionLevel: this.form.value.rateSatisfactionLevel,
      rateSatisfactionEmail: this.form.value.rateSatisfactionEmail,
      rateSatisfactionEmailVideoMsg: this.form.value.rateSatisfactionEmailVideoMsg,
      rateSatisfactionWebsiteArticle: this.form.value.rateSatisfactionWebsiteArticle,
      rateSatisfactionWebsiteVideo: this.form.value.rateSatisfactionWebsiteVideo,
      satisfactionWithFrequencyOfEmail: this.form.value.satisfactionWithFrequencyOfEmail,
      whatTypeOfContentWouldYouLikeToSeeMore: this.checkboxOutput,
      iamMostInterestedInReadingArticlesWrittenBy: this.form.value.iAmMostInterestedInReadingArticlesWrittenBy,
      rateRecommendToFriendOrFamily: this.form.value.rateRecommendToFriendOrFamily,
      gender: this.form.value.gender !== '' ? this.form.value.gender : null,
      ageRange: this.form.value.ageRange !== '' ? this.form.value.ageRange : null,
      iamA: this.form.value.iAmA !== '' ? this.form.value.iAmA : null,
      userId: this.userId,
      c: this.c
    };

    this.surveysService
        .create(postData)
        .subscribe(this.handleResponse.bind(this));

    this.gaService.event('click', 'internal', 'SUBMIT SURVEY CAYC0460');

    this.router.navigate(['/survey-confirm']);
  }

  generateArray(n: number): number[] {
    return Array.from(Array(n).keys());
  }

  //

  private handleResponse(success: any) {
    console.log(success);
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement[] = this.el.nativeElement.querySelectorAll('.ng-invalid');
    const first = firstInvalidControl[1]; //without smooth behavior
    // first.focus();
    const anchorId = first.id.substring(0, first.id.length - 2);

    const aa: HTMLElement = this.el.nativeElement.querySelector("#anchor-" + anchorId);
    aa.focus();

    window.scroll({
      top: this.getTopOffset(aa),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 0;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

}
