import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';


const DefaultArticleCode = 'CAYC0518 05/21';


@Injectable({
  providedIn: 'root'
})
export class PageArticleService {

  private article$ = new BehaviorSubject<string>(DefaultArticleCode);


  get article() {
    return this.article$
               .asObservable()
               .pipe(
                 map(id => id.trim().length > 0 ? id : DefaultArticleCode),
                 shareReplay(1)
               );
  }

  updateArticle(article: string) {
    setTimeout(() => { this.article$.next(article); }, 0);
  }

}
