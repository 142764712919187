import { Component, ViewChild, ElementRef } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { PageArticleService } from './api/page-article.service';
import { AlternateHeaderService } from './api/alternate-header.service';


@Component({
  selector: 'cayston-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Cayston';
  year = new Date().getFullYear();
  readMoreHidden = true;
  noticeVisible = true;
  articleId$ = this.articleService.article;
  alternateHeader$ = this.alternateHeaderService.alternateHeader;

  @ViewChild('tap', { static: true })
  tap: ElementRef<HTMLElement>;
  @ViewChild('isi', { static: true })
  isi: ElementRef<HTMLElement>;
  @ViewChild('externalPopup', { static: false })
  popup: ModalDirective;
  observer: IntersectionObserver;


  constructor(private gaService: GoogleAnalyticsService, 
              private articleService: PageArticleService,
              private alternateHeaderService: AlternateHeaderService) { }

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry =>
        entry.intersectionRatio > 0
          ? this.tap.nativeElement.classList.add('d-none')
          : this.tap.nativeElement.classList.remove('d-none')
      );
    });
    //this.observer.observe(this.isi.nativeElement);
  }


  scrollToISI() {
    this.isi.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  openCayston() {
    window.open('https://www.cayston.com', '_blank', 'noopener');
    this.gaService.event('click', 'external', 'CAYSTON Logo');
  }

  openExternalPopup() {
    this.popup.show();
    this.gaService.event('click', 'external', 'www.fda.gov/medwatch');
  }

  openMedwatch() {
    window.open('https://www.fda.gov/medwatch', '_blank', 'noopener');
    this.popup.hide();
  }

  showCopy(event: Event) {
    const elem = (event.target as HTMLImageElement).parentElement as HTMLDivElement;
    const activeElem = document.querySelector('header.mobile [class*=box-].active');

    if (activeElem) {
      activeElem.classList.remove('active');

      if (activeElem.classList.value === elem.classList.value)
        return;
    }

    elem.classList.add('active');
    const className = elem.classList[0];
    const tileName = className === 'box-1' ? 'Emily Tile' : className === 'box-2' ? 'Chris Tile' : 'Mary Tile';
    this.gaService.event('click', 'internal', tileName);
  }

  mouseEnter(event: Event) {
    const elem = (event.target as HTMLImageElement).parentElement as HTMLDivElement;
    const className = elem.classList[0];
    const tileName = className === 'box-1' ? 'Emily Tile' : className === 'box-2' ? 'Chris Tile' : 'Mary Tile';
    this.gaService.event('hover', 'internal', 'Hover: ' + tileName);
  }
}
