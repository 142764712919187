import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'cayston-opt-out-confirm',
  templateUrl: './opt-out-confirm.component.html',
  styleUrls: ['./opt-out-confirm.component.scss']
})
export class OptOutConfirmPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
